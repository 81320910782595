@use "./variables" as *;
@use "./mixins" as *;

.about-div {
	@include flexDirection(column);
	justify-content: center;
	align-items: center;
	padding: 0 75px;
	height: 40vh;
}

.intro-para,
.about-tag-line {
	color: $textColourWhite;
	font-size: 1.8vw;
	letter-spacing: 1.5px;
	margin-left: 50px;
	@media screen and (max-width: $large) {
		font-size: 3vw;
		letter-spacing: 1px;
		color: $backgroundGreen;
		margin-left: 0;
	}
}

.about-tag-line {
	margin-top: 40px;
	font-size: 3vw;
	color: $orangeBtn;
	@media screen and (max-width: $large) {
		font-size: 5vw;
	}
}

.mob-color {
	@media screen and (max-width: $large) {
		background-color: $textColourWhite;
	}
}

.skills-aside {
	@include flexCenter();
	@media screen and (max-width: $large) {
		position: absolute;
		bottom: 5%;
		left: 22%;
	}
	@media screen and (max-width: $small) {
		left: 12%;
	}
}

.skills-div {
	height: auto;
	@include flexDirection(column);
	flex-wrap: wrap;
}

.three-skill-div {
	@include flexDirection(row);
	margin-top: 2rem;
}

.skill {
	border-radius: 30px;
	background: linear-gradient(145deg, #ffffff, #dcddd7);
	box-shadow: 10px 10px 15px #cdcfc9, -10px -10px 15px #ffffff;
	color: $backgroundGreen;
	width: 150px;
	height: 150px;
	@include flexDirection(column);
	justify-content: center;
	text-align: center;
	margin-left: 1.2rem;
	i {
		font-size: 4vw;
		margin-bottom: 1rem;
	}
	h4 {
		font-size: 1.8vw;
		letter-spacing: 2px;
	}
	@media screen and (max-width: $large) {
		width: 15vw;
		height: 15vw;
		h4 {
			font-size: 3.5vw;
		}
	}
	@media screen and (max-width: $small) {
		width: 20vw;
		height: 20vw;
		border-radius: 20px;
		i {
			font-size: 8vw;
		}
		h4 {
			font-size: 3.5vw;
		}
	}
}
