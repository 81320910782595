@use "./mixins" as *;
@use "./variables" as *;

.green-roll {
	@include greenRoll(64%);
	@media screen and (max-width: $large) {
		@include greenRoll(100%);
	}
}

.sect-title {
	@include whiteOut();
	color: $backgroundGreen;
	font-size: 11vw;
	display: inline-block;
	@include flexCenter();
}
