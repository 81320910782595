@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Bebas Neue", cursive;
}

body {
	background-color: #f4f6ef;
}

section {
	display: flex;
	flex-direction: row;
	position: relative;
}
