@use "./variables" as *;
@use "./mixins" as *;

.green-full {
	color: $backgroundGreen;
	width: 100%;
	height: 100vh;
}

.project-div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
	color: $textColourWhite;
}

.slide-back,
.slide-forward {
	cursor: pointer;
	width: 8%;
	height: 100vh;
	position: absolute;
	display: flex;
	justify-content: left;
	align-items: center;
	i {
		font-size: 3rem;
		color: $orangeBtn;
		@media screen and (max-width: $small) {
			font-size: 2rem;
		}
	}
}

.slide-back {
	left: 110px;
	@media screen and (max-width: $large) {
		left: 0;
	}
}

.slide-forward {
	justify-content: right;
	right: 10px;
	@media screen and (max-width: $large) {
		right: 0;
	}
}

.pro-title {
	font-size: 4vw;
	@media screen and (max-width: $large) {
		font-size: 6vw;
	}
}

.pro-para {
	font-size: 1.8vw;
	max-width: 600px;
	margin: 30px 0 50px;
	letter-spacing: 1.5px;
	@media screen and (max-width: $large) {
		font-size: 3vw;
		letter-spacing: 1px;
	}
	@media screen and (max-width: $small) {
		max-width: 300px;
	}
}

.link-btn {
	padding: 10px 20px;
	background-color: #c84c09;
	color: inherit;
	text-decoration: none;
	border-radius: 5px;
	letter-spacing: 1px;
	width: 150px;
	margin: 0 10px 0 10px;
	z-index: 5;
	&:hover,
	&:focus {
		@include hoverBtn();
	}
	@media screen and (max-width: $small) {
		max-width: 80px;
		font-size: 3vw;
	}
}

.project-id {
	@include whiteOut();
}

.project-aside {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 35%;
	@media screen and (max-width: $large) {
		width: auto;
		position: absolute;
		bottom: 10%;
		left: 16%;
	}
	@media screen and (max-width: $small) {
		width: 70%;
		position: absolute;
		bottom: 20%;
		left: 18%;
	}
}

.img-div {
	display: flex;
	justify-content: space-around;
}

.desktop-img1 {
	width: 55%;
}

.tablet-img1,
.tablet-img2 {
	width: 30%;
	height: auto;
}

.mobile-img1 {
	width: 25%;
	height: auto;
}

.mob-img {
	width: auto;
	@media screen and (max-width: $large) {
		max-height: 300px;
	}
	@media screen and (max-width: $small) {
		max-height: 150px;
	}
}
