@use "./variables" as *;

@mixin whiteOut {
	text-shadow: -1px 1px 0 $textColourWhite, 1px 1px 0 $textColourWhite,
		1px -1px 0 $textColourWhite, -1px -1px 0 $textColourWhite;
	color: $backgroundGreen;
}

@mixin greenRoll($width) {
	background-color: $backgroundGreen;
	width: $width;
	height: 100vh;
	padding-left: 100px;
	@media screen and (max-width: $large) {
		padding-left: 0;
	}
}

@mixin flexCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin flexDirection($direction) {
	display: flex;
	flex-direction: $direction;
}

@mixin hoverBtn($color: #9b3904) {
	background-color: $color;
	box-shadow: 0 0.5em 0.5em -0.4em #181d27;
	transform: translateY(-0.05em);
}
