@use "./variables" as *;
@use "./mixins" as *;

.contact-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 40px;
}

.form-div {
	width: 70%;
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	label {
		color: $textColourWhite;
		font-size: 2.2vw;
		@media screen and (max-width: $large) {
			color: $backgroundGreen;
			font-size: 3.5vw;
		}
		span {
			font-size: 1.2vw;
			color: $orangeBtn;
			position: absolute;
		}
	}
	input {
		width: 100%;
		height: 30px;
		border: none;
	}
	textarea {
		height: 80px;
		border: none;
	}
}

.submit-btn {
	font-size: 1.5vw;
	background-color: #c84c09;
	margin-top: 40px;
	padding: 5px 50px;
	border-radius: 5px;
	color: $textColourWhite;
	border: none;
	cursor: pointer;
	&:hover,
	&:focus {
		@include hoverBtn();
	}
	@media screen and (max-width: $large) {
		font-size: 3vw;
	}
}

.contact-aside {
	@include flexCenter();
	@include flexDirection(column);
	margin: auto;
	font-size: 1.8vw;
	color: $backgroundGreen;
	@media screen and (max-width: $large) {
		position: absolute;
		bottom: 20%;
		left: 25%;
		font-size: 3vw;
	}
	@media screen and (max-width: $small) {
		font-size: 4vw;
		bottom: 5%;
		left: 18%;
	}
}

.contact-social {
	margin-top: 50px;
	@media screen and (max-width: $large) {
		margin-top: 20px;
	}
}

.contact-link {
	font-size: 3vw;
	margin: auto 20px;
	color: $orangeBtn;
	cursor: pointer;
	&:hover,
	&:focus {
		color: #9b3904;
	}
	@media screen and (max-width: $large) {
		font-size: 5vw;
	}
	@media screen and (max-width: $small) {
		font-size: 6vw;
	}
}
