@use "./variables" as *;
@use "./mixins" as *;

.skip-to-main {
	background-color: $orangeBtn;
	color: $textColourWhite;
	text-decoration: none;
	padding: 0.5rem 1.5rem;
	position: absolute;
	transform: translateY(-120%);
	border-radius: 0 0 0.25rem 0.25rem;
	left: 100px;
	&:focus {
		transform: translateY(0);
	}
}

.sidenav {
	height: 100%;
	width: 100px;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: black;
	background-color: $textColourWhite;
}

.nav-link {
	writing-mode: vertical-rl;
	text-orientation: upright;
	display: block;
	margin: 20px auto;
	font-size: 1.2rem;
	text-decoration: none;
	cursor: pointer;
	&:visited {
		color: black;
	}
	&:hover {
		font-weight: 900;
	}
}

.social-links {
	a {
		color: $orangeBtn;
		&:visited {
			color: $orangeBtn;
		}
		&:hover {
			color: #9b3904;
		}
	}
}

.logo-img {
	width: 30px;
	vertical-align: inherit;
	border-radius: 50%;
}

// Mobile Nav

.nav-btn {
	position: absolute;
	z-index: 5;
	top: 15px;
	left: 15px;
	background-color: $backgroundGreen;
	border: solid 1.5px $textColourWhite;
	border-radius: 20%;
	padding: 4px 7px;
	i {
		font-size: 1.5rem;
		color: $orangeBtn;
	}
	&:focus {
		border: solid 1.5px $orangeBtn;
	}
}

.mob-nav-div {
	position: absolute;
	z-index: 5;
	width: 100vw;
	height: 100vh;
	@include flexCenter;
	flex-direction: column;
	color: $textColourWhite;
	background-color: #121213c7;
}

.mob-nav-link {
	font-size: 3rem;
	text-decoration: none;
	&:visited {
		color: $textColourWhite;
	}
}

.mob-social-links {
	margin-top: 40px;
	width: 30%;
	display: flex;
	justify-content: space-around;
	a {
		color: $orangeBtn;
		&:visited {
			color: $orangeBtn;
		}
	}
	@media screen and (max-width: $small) {
		width: 60%;
	}
}
